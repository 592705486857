import styled from "@emotion/styled";
import { OrderData } from "../api/types";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { CountOrder } from "../api/helper";
import SwipeDownIcon from "@mui/icons-material/SwipeDown";
import {
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
  Type,
} from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";

interface Props {
  order: OrderData;
  onClick?: () => void;
  isComplete?: boolean;
  onSwipe?: () => void;
}

const trailingActions = (onSwipe: () => void) => (
  <TrailingActions>
    <SwipeAction onClick={() => onSwipe()}>
      <div className="flex h-full items-center justify-end bg-red-500">
        <p className="flex  whitespace-nowrap text-xl font-bold text-white">
          発券済みにする
        </p>
      </div>
    </SwipeAction>
  </TrailingActions>
);

const OrderCard = ({
  order,
  onClick,
  isComplete: isComplete,
  onSwipe,
}: Props) => {
  const [OrderTitle, setOrderTitle] = useState<string[]>([]);
  const [OrderCount, setOrderCount] = useState<number[]>([]);
  const CountOrderData = useRef<CountOrder>(
    new CountOrder(setOrderCount, setOrderTitle)
  );

  useEffect(() => {
    CountOrderData.current.menuCount(order.menu);
  }, [order]);
  return (
    <Wrapper onClick={onClick}>
      <div
        className={classNames("grid h-full w-full rounded-[10px] p-3", {
          "bg-black/20": isComplete,
        })}
        style={{
          gridTemplate: `
           ". number ." 93px
            ".  table ." 300px
            ".  info  ." 80px
            ".  .   .  " auto/ 
            auto 1fr auto
          `,
        }}
      >
        {onSwipe ? (
          <SwipeableList
            style={{
              gridArea: "number",
            }}
            type={Type.MS}
          >
            <SwipeableListItem
              maxSwipe={1.0}
              trailingActions={trailingActions(() => {
                setTimeout(() => {
                  onSwipe();
                }, 500);
              })}
            >
              <OrderNumber
                className={classNames("z-10 grid", {
                  "bg-zinc-500 text-gray-400": isComplete,
                })}
              >
                {"M" + order.orderNumber}
              </OrderNumber>
            </SwipeableListItem>
          </SwipeableList>
        ) : (
          <OrderNumber
            style={{
              gridArea: "number",
            }}
            className={classNames("grid", {
              "bg-zinc-500 text-gray-400": isComplete,
            })}
          >
            {"M" + order.orderNumber}
          </OrderNumber>
        )}
        <Order style={{ gridArea: "table" }}>
          <p
            className={classNames("grid font-bold", {
              "text-red-300": isComplete,
            })}
          >
            {isComplete ? "発券済み" : "未発券"}
          </p>
          <p className="text-right text-xl">{`${order.menu.length}個`}</p>
          {OrderTitle.map((menu, index) => {
            return (
              <div key={index}>
                <p className="justify-star flex w-full grid-cols-[20px_auto_20px] items-start border-b border-solid border-gray-400 text-lg text-slate-800">
                  <span className="font-bold">{index + 1}: </span>
                  <span className="mr-auto">{menu}</span>
                  <span className="text-[#F25F1D]">×{OrderCount[index]}</span>
                </p>
              </div>
            );
          })}
        </Order>
        {/* orderTitleの全文字数が50文字以上or9項目以上の場合、スクロール可能アニメーションを表示する */}
        {OrderTitle.join("").length >= 50 || OrderTitle.length > 9 ? (
          <SwipeDownIcon
            className={classNames(
              "relative left-[70%] top-[80%] animate-flashing text-3xl",
              {
                "text-gray-400": isComplete,
              }
            )}
            style={{ gridArea: "table" }}
          />
        ) : null}
        <OrderInfo style={{ gridArea: "info" }}>
          <div>
            {order.payment + " " + order.date.toDate().toLocaleTimeString()}
          </div>
          <div>{"mail: " + order.user.mailAddress}</div>
          <div>{"ID: " + order.id}</div>
        </OrderInfo>
      </div>
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  width: 250px;
  flex-shrink: 0;
  margin: 0 20px;
  background-color: #ffffff;
  border-radius: 10px;
  margin-top: 14px;
`;
export const OrderNumber = styled.div`
  width: 100%;
  background-color: #000000cb;
  color: #ffffff;
  font-size: 56px;
  font-weight: bold;
  text-align: center;
  border-radius: 10px;
`;

export const Order = styled.div`
  width: 100%;
  color: #707070;
  font-size: 26px;
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  flex-grow: 1;
`;

const OrderInfo = styled.div`
  width: 100%;
  color: #006c9b;
  font-size: 16px;
  text-align: center;
`;
export default OrderCard;
