import {
  CountOrder,
  GetSpecificData,
  IsGetSystemStatus,
  StartOfToday,
  TodayOrderGet,
  updateSystemStatus,
} from "../api/helper";
import { useEffect, useRef, useState } from "react";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import TableComp from "../component/TableComp";
import { db } from "../api/Firebase";
import { OrderList } from "../component/OrderList";
import { MenuData, OrderData } from "../api/types";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { Button, Modal } from "@mui/material";
import classNames from "classnames";
import { LoadingAnimation } from "../component/LoadingAnimation";
import { Link } from "react-router-dom";

const Admin = () => {
  const [todayOrder, setTodayOrder] = useState<OrderData[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [orderCooked, setOrderCooked] = useState<OrderData[]>([]);
  const [isNewOrder, setIsNewOrder] = useState(false);
  const [OrderTitle, setOrderTitle] = useState<string[]>([]);
  const [OrderCount, setOrderCount] = useState<number[]>([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isSystemDown, setIsSystemDown] = useState(false);
  const [menu, setMenu] = useState<MenuData>();
  const CountOrderData = useRef<CountOrder>(
    new CountOrder(setOrderCount, setOrderTitle)
  );
  // 無意味な変更
  useEffect(() => {
    (async () => {
      GetSpecificData("menu", "VzQk5jNwTq1EHPAKUXlt").then((data) => {
        setMenu(data as MenuData);
      });
      const isSystemStatus = await IsGetSystemStatus();
      setIsOpenModal(!isSystemStatus);
      setIsSystemDown(!isSystemStatus);

      const data = (await TodayOrderGet("order", [
        "cooked",
        "complete",
      ])) as OrderData[];
      setOrderCooked(data);

      const currentOrder = (await TodayOrderGet("order", [
        "ordered",
      ])) as OrderData[];
      setTodayOrder(currentOrder);
      CountOrderData.current.ListDefault(currentOrder as OrderData[]);
      setIsFetching(true);
      const q = query(
        collection(db, "order"),
        where("date", ">=", StartOfToday()),
        where("isStatus", "in", ["ordered", "cooked", "complete"])
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          const order = change.doc.data() as OrderData;
          if (change.type === "modified") {
            if (
              order.orderNumber !== undefined &&
              order.isStatus === "ordered"
            ) {
              setTodayOrder((prev) => [...prev, order]);
              CountOrderData.current.ListAdd(order);
              setIsNewOrder(true);
            } else if (order.isStatus === "cooked") {
              CountOrderData.current.ListRemove(order);
              setTodayOrder((prev) =>
                prev.filter((prevOrder) => prevOrder.id !== order.id)
              );
              setOrderCooked((prev) => [...prev, order]);
            } else if (order.isStatus === "complete") {
              CountOrderData.current.ListRemove(order);
              setTodayOrder((prev) =>
                prev.filter((prevOrder) => prevOrder.id !== order.id)
              );
              setOrderCooked((prev) => [...prev, order]);
            }
          } else if (change.type === "removed") {
            CountOrderData.current.ListRemove(order);
            setTodayOrder((prev) =>
              prev.filter((prevOrder) => prevOrder.id !== order.id)
            );
            setOrderCooked((prev) =>
              prev.filter((prevOrder) => prevOrder.id !== order.id)
            );
          }
        });
      });

      return unsubscribe;
    })();
  }, []);

  return (
    <>
      <main
        className="grid gap-5 bg-black p-5"
        style={{
          gridTemplate: `
        " ref   switch camera list " 89px 
        " card  card    card  list " auto
        /auto auto 100px 280px
      `,
        }}
      >
        <div className="grid" style={{ gridArea: "card" }}>
          <OrderList
            toDayOrder={todayOrder as OrderData[]}
            orderCooked={orderCooked as OrderData[]}
            isFetching={isFetching}
            isNewOrder={isNewOrder}
            setIsNewOrder={setIsNewOrder}
          />
        </div>
        <div className="grid" style={{ gridArea: "ref" }}>
          <button
            className="cursor-pointer rounded-2xl bg-sky-600 text-5xl font-bold text-white"
            onClick={() => {
              window.location.reload();
            }}
          >
            更新
          </button>
        </div>
        <div className="grid" style={{ gridArea: "switch" }}>
          <Link to="/status">
            <Button
              fullWidth
              className={classNames(
                "flex h-full cursor-pointer items-center justify-center rounded-2xl bg-indigo-500 text-5xl font-bold text-white",
                { "animate-pulse": isSystemDown }
              )}
            >
              販売切替
            </Button>
          </Link>
        </div>
        <div className="grid" style={{ gridArea: "camera" }}>
          <Link to="/upload" className="relative h-full w-full cursor-pointer">
            {menu ? (
              <>
                <img
                  src={menu.image}
                  alt="menuImage"
                  className="h-full w-full rounded-2xl object-fill"
                />
                <CameraAltIcon className="absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 bg-black/20 text-7xl text-white/80" />
              </>
            ) : (
              <LoadingAnimation type={"leapfrog"} top="-50%" />
            )}
          </Link>
        </div>
        <div className="grid" style={{ gridArea: "list" }}>
          <TableComp
            title={OrderTitle}
            count={OrderCount}
            index={todayOrder.length}
          />
        </div>
      </main>
      <Modal open={isOpenModal} onClose={() => setIsOpenModal(false)}>
        <div className="fixed left-1/2 top-1/2 z-10 h-1/3 w-1/3 -translate-x-1/2 -translate-y-1/2 transform rounded-2xl bg-white p-5">
          <h1 className="mt-5 text-center text-3xl font-bold">
            システムが停止中です
          </h1>
          <p className="my-4 px-2 text-xl">
            現在システムが停止中です。システムを再起動しますか？
          </p>
          <Button
            className="absolute bottom-2 left-2 mr-5 w-[100px] font-bold"
            onClick={() => {
              setIsOpenModal(false);
            }}
          >
            閉じる
          </Button>
          <Button
            className="absolute bottom-2 right-2 w-[100px] bg-sky-600 font-bold"
            variant="contained"
            onClick={async () => {
              setIsOpenModal(false);
              setIsSystemDown(false);
              await updateSystemStatus(true);
            }}
          >
            起動
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Admin;
