import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { OrderData } from "../api/types";
import { LoadingAnimation } from "./LoadingAnimation";
import { DeleteModal } from "./DeleteModal";
import OrderCard from "./OrderCard";
import Snackbar from "@mui/material/Snackbar";
import Slide, { SlideProps } from "@mui/material/Slide";
import useSound from "use-sound";
import { OrderStatusChange } from "../api/helper";

interface OrderProps {
  toDayOrder: OrderData[];
  isFetching: boolean;
  orderCooked: OrderData[];
  isNewOrder: boolean;
  setIsNewOrder: (value: boolean) => void;
}

export const OrderList = (props: OrderProps) => {
  const [isModal, setIsModal] = useState(false);
  const [selectOrder, setSelectOrder] = useState<OrderData>();

  type TransitionProps = Omit<SlideProps, "direction">;

  const TransitionRight = (props: TransitionProps) => {
    return <Slide {...props} direction="right" />;
  };
  const [play] = useSound("/se/qr.wav");

  useEffect(() => {
    props?.isNewOrder === true && play();
  }, [play, props?.isNewOrder]);

  useEffect(() => {
    if (props?.toDayOrder.length <= 6 && props.isNewOrder === true) {
      const element = document.getElementById(
        `order_${props.toDayOrder.length - 1}`
      );
      element?.scrollIntoView({ behavior: "smooth" });
    }
  }, [props?.toDayOrder, props.isNewOrder]);

  return (
    <>
      <Wrapper>
        {!props.isFetching && <LoadingAnimation type={"leapfrog"} top="25%" />}
        {props.orderCooked.map((order: OrderData, index) => {
          return (
            <div key={index}>
              <PrimaryNumber className="text-white/40">
                {index + 1}
              </PrimaryNumber>
              <OrderCard order={order} isComplete={true} />
            </div>
          );
        })}
        {props.toDayOrder.map((order: OrderData, index) => {
          return (
            <div key={index} id={`order_${index}`}>
              <PrimaryNumber>{index + 1}</PrimaryNumber>
              <OrderCard
                order={order}
                onClick={() => {
                  setIsModal(true);
                  setSelectOrder(order);
                }}
                onSwipe={() => {
                  OrderStatusChange("order", order, "complete");
                }}
              />
            </div>
          );
        })}
        {props.toDayOrder.length === 0 &&
          props.orderCooked.length === 0 &&
          props.isFetching && <NoneOrder>本日の注文はありません</NoneOrder>}
      </Wrapper>
      {selectOrder && (
        <DeleteModal
          isModal={isModal}
          onClose={() => {
            setIsModal(false);
          }}
          orderData={selectOrder}
          onClick={() => {
            OrderStatusChange("order", selectOrder, "complete");
          }}
        />
      )}
      <Snackbar
        open={props.isNewOrder}
        TransitionComponent={TransitionRight}
        autoHideDuration={5000}
        onClose={() => {
          props.setIsNewOrder(false);
        }}
        message="新しい注文が入りました"
        key={TransitionRight.name}
        onClick={() => {
          props.setIsNewOrder(false);
          const element = document.getElementById(
            `order_${props.toDayOrder.length - 1}`
          );
          element?.scrollIntoView({ behavior: "smooth" });
        }}
      />
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  overflow-x: scroll;
  width: 100%;
  background: transparent linear-gradient(200deg, #00566b 0%, #1c1b64 100%) 0%
    0% no-repeat padding-box;
  border-radius: 16px;
`;

const PrimaryNumber = styled.div`
  font-size: 46px;
  font-weight: bold;
  color: #000000;
  margin: auto;
  text-align: center;
  margin-top: 0px;
  color: #ffffff;
`;

const NoneOrder = styled.div`
  font-size: 46px;
  color: #ffffff;
  margin: auto;
  text-align: center;
`;
