import {
  DocumentData,
  orderBy,
  query,
  where,
  collection,
  getDocs,
  doc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { db } from "./Firebase";
import { MenuData, OrderData, SystemData } from "./types";
type orderStatus = "ordered" | "cooked" | "complete";

export const IsGetSystemStatus = async () => {
  try {
    const docRef = doc(db, "system", "systemData");
    const docSnap = await getDoc(docRef);
    const data = docSnap.data() as SystemData;
    return data.isStatus;
  } catch (e) {
    window.alert("システムの状態を取得できませんでした。");
    throw new Error("システムの状態を取得できませんでした。");
  }
};

export const updateSystemStatus = async (isStatus: boolean) => {
  const systemDocRef = doc(db, "system", "systemData");
  try {
    await updateDoc(systemDocRef, {
      isStatus,
    });
  } catch (error) {
    window.alert("システムの状態を変更できませんでした。");
    throw new Error("システムの状態を変更できませんでした。");
  }
};

export const StartOfToday = () => {
  const now = new Date();
  const startOfToday = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate()
  );
  return startOfToday;
};

export const TodayOrderGet = async (
  docId: string,
  status: orderStatus[]
): Promise<DocumentData[]> => {
  const q = query(
    collection(db, docId),
    orderBy("date", "asc"),
    where("date", ">", StartOfToday()),
    where("isStatus", "in", status)
  );
  const querySnapshot = await getDocs(q);
  const data = querySnapshot.docs.map((doc) => doc.data());
  return data;
};
export class CountOrder {
  private oneTodayOrder: OrderData[] = [];
  private oneOrderMenu: MenuData[] = [];
  private titleList: string[] = [];
  words: string[] = [];
  constructor(
    private readonly setOrderCount: (count: number[]) => void,
    private readonly setOrderTitle: (titles: string[]) => void
  ) {}
  TitleCountMethod = () => {
    this.oneTodayOrder.map((order: OrderData) => {
      return order.menu.map((menu: DocumentData) => {
        return this.titleList.push(menu.title);
      });
    });
    this.words = this.titleList;
    const titles = this.getTitle();
    const count = this.getCount();
    this.setOrderCount(count);
    this.setOrderTitle(titles);
    this.titleList = [];
  };

  oneOrderCount = () => {
    this.oneOrderMenu.map((menu: DocumentData) => {
      return this.titleList.push(menu.title);
    });
    this.words = this.titleList;
    const titles = this.getTitle();
    const count = this.getCount();
    this.setOrderCount(count);
    this.setOrderTitle(titles);
    this.titleList = [];
  };
  historyOrderCount = () => {
    const titles = this.getTitle();
    const count = this.getCount();
    this.setOrderCount(count);
    this.setOrderTitle(titles);
    this.titleList = [];
  };

  menuCount = (oneOrderCount: MenuData[]) => {
    this.oneOrderMenu = oneOrderCount;
    this.oneOrderCount();
  };

  titleCount = (oneOrderCount: string) => {
    this.words.push(oneOrderCount);
    this.historyOrderCount();
  };

  ListDefault = (oneTodayOrder: OrderData[]) => {
    if (oneTodayOrder.length !== 0) {
      this.oneTodayOrder = oneTodayOrder;
      this.TitleCountMethod();
    }
  };

  ListAdd = (oneTodayOrder: OrderData) => {
    this.oneTodayOrder.push(oneTodayOrder);
    this.TitleCountMethod();
  };

  ListRemove = (oneTodayOrder: OrderData) => {
    this.oneTodayOrder = this.oneTodayOrder.filter(
      (order) => order.id !== oneTodayOrder.id
    );
    this.TitleCountMethod();
  };

  //重複カウンター処理
  private DuplicateReduce() {
    return this.words.filter((x, i, self) => {
      return self.indexOf(x) === i;
    });
  }
  private getTitle(): string[] {
    return this.DuplicateReduce();
  }
  private getCount(): number[] {
    return this.DuplicateReduce().map((name) => {
      return this.words.filter((x) => x === name).length;
    });
  }
}

export const OrderStatusChange = async (
  docId: string,
  orderData: OrderData,
  status: string
) => {
  const docRef = doc(db, docId, orderData.id);
  await updateDoc(docRef, {
    isStatus: status,
  });
};

export const AllMenuDataUpdate = (menuData: MenuData[], quantity: number) => {
  const isSale = quantity !== 0;
  const promises = menuData
    .filter((menuData) =>
      isSale ? menuData.quantity === 0 : menuData.quantity !== 0
    )
    .map(async (menu) => {
      await updateDoc(doc(db, "menu", menu.id), {
        quantity: isSale ? quantity : 0,
      });
    });
  Promise.all(promises).then(() => {
    window.location.reload();
  });
};

export const MenuDataUpdate = async (menuData: MenuData, quantity: number) => {
  await updateDoc(doc(db, "menu", menuData.id), {
    quantity: quantity,
  });
  window.location.reload();
};

export const GetSpecificData = async (docId: string, collectionId: string) => {
  const docRef = doc(db, docId, collectionId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return false;
  }
};

export const dateFormatter = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month}-${day}`;
};

export const checkPassword = (
  password: string,
  setError: (error: boolean) => void
) => {
  if (password.length < 4) {
    setError(false);
    return false;
  }
  password !== "1141" && setError(true);
  return password === "1141";
};

export const imageResize = (image: string) => {
  return new Promise((resolve) => {
    let base64;
    const img = new Image();
    img.src = image;
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const MAX_WIDTH = 500;
      const MAX_HEIGHT = 500;
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }

      canvas.width = width;
      canvas.height = height;

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const ctx = canvas.getContext("2d")!;
      ctx.drawImage(img, 0, 0, width, height);

      const resizedImage = canvas.toDataURL("image/jpeg");
      base64 = resizedImage;
      resolve(base64);
    };
  });
};

export const isExtensionValid = (fileName: string) => {
  const validExtensions = [".jpg", ".jpeg", ".png", ".JPG", ".JPEG", ".PNG"];
  const extension = fileName.slice(fileName.lastIndexOf("."));
  return validExtensions.includes(extension);
};
