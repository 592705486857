import { useEffect, useRef, useState } from "react";
import { OrderData } from "../api/types";
import { CountOrder } from "../api/helper";
import { Modal } from "@mui/material";
import classNames from "classnames";
import styled from "@emotion/styled";
import CancelIcon from "@mui/icons-material/Cancel";
interface ModalProps {
  isModal: boolean;
  onClose: () => void;
  orderData: OrderData;
  onClick: () => void;
}

export const DeleteModal: React.FC<ModalProps> = (props: ModalProps) => {
  const [countTitle, setCountTitle] = useState<string[]>([]);
  const [count, setCount] = useState<number[]>([]);
  const GetCount = useRef(new CountOrder(setCount, setCountTitle));

  useEffect(() => {
    GetCount.current.menuCount(props.orderData.menu);
  }, [props.orderData]);
  return (
    <>
      {props.isModal ? (
        <Modal
          open={props.isModal}
          disableAutoFocus={true}
          onClose={props.onClose}
        >
          <div className="fixed left-1/2 top-1/2 z-10 h-3/4 w-1/2 -translate-x-1/2 -translate-y-[60%] transform rounded-t-2xl bg-white">
            <CancelIcon
              className="absolute right-0 m-1"
              fontSize="large"
              onClick={props.onClose}
            />
            <div className="h-full w-full overflow-scroll p-5 text-center">
              {countTitle.map((menu, index) => {
                return (
                  <div
                    key={index}
                    className="grid items-center justify-start overflow-y-scroll border-b border-solid border-gray-400 p-2 text-xl last:border-none"
                    style={{
                      gridTemplate: `
                        "index title count img" 1fr/
                         auto 3fr 1fr 1fr`,
                    }}
                  >
                    <p
                      className="items-center justify-center"
                      style={{ gridArea: "index" }}
                    >
                      {index + 1}:{" "}
                    </p>
                    <p
                      className="ml-5 flex justify-start text-left"
                      style={{ gridArea: "title" }}
                    >
                      {menu}
                    </p>
                    <CountWrapper
                      className="flex items-center justify-center"
                      style={{ gridArea: "count" }}
                    >
                      ×{count[index]}
                    </CountWrapper>
                    {props.orderData.menu
                      .filter((order) => order.title === menu)
                      .map((order, i) => {
                        return (
                          <img
                            key={i}
                            src={order.image}
                            alt={order.title}
                            className="flex justify-end"
                            style={{ gridArea: "img" }}
                          />
                        );
                      })}
                  </div>
                );
              })}
            </div>
            <div className="rounded-b-2xl bg-white">
              <div className="grid grid-flow-row items-center justify-center gap-y-3 py-3">
                <button
                  className={classNames(
                    "m-5 h-[40px] w-[200px] rounded-full bg-black/10 text-red-500"
                  )}
                  onClick={() => {
                    props.onClick();
                    props.onClose();
                  }}
                >
                  発券済みにする
                </button>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
};

const CountWrapper = styled.div`
  margin-left: 10px;
  font-size: 30px;
  color: #f25f1d;
  width: 100px;
`;
