import styled from "@emotion/styled";
import { Fab, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useEffect } from "react";
interface Props {
  purchaseCount: number;
  setPurchaseCount: (quantity: number) => void;
  isSetDisabled?: boolean;
  setIsSendDisabled?: (value: boolean) => void;
}

export const MultiplePurchase = ({
  purchaseCount,
  setPurchaseCount,
  isSetDisabled,
  setIsSendDisabled,
}: Props) => {
  useEffect(() => {
    if (isNaN(Number(purchaseCount)) || purchaseCount < 0) {
      setIsSendDisabled?.(true);
    } else {
      setPurchaseCount(Number(purchaseCount));
      setIsSendDisabled?.(false);
    }
  }, [purchaseCount, setIsSendDisabled, setPurchaseCount]);

  return (
    <Wrapper>
      <Fab
        size="small"
        onClick={() => {
          if (purchaseCount > 1) {
            setPurchaseCount(purchaseCount - 1);
          }
        }}
        style={{
          backgroundColor: purchaseCount > 1 ? "#1D98F2" : "#707070",
          boxShadow: "none",
        }}
      >
        <RemoveIcon style={{ color: "white" }} />
      </Fab>
      <TextField
        value={purchaseCount}
        error={isSetDisabled}
        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
        type="text"
        placeholder="個数"
        onChange={(e) => {
          if (!isNaN(Number(e.target.value))) {
            setPurchaseCount(Number(e.target.value));
          }
        }}
        variant="outlined"
        className="mx-auto w-[80%]"
      />

      <Fab
        size={"small"}
        onClick={() => {
          setPurchaseCount(purchaseCount + 1);
        }}
        style={{
          backgroundColor: "#F25F1D",
          boxShadow: "none",
        }}
      >
        <AddIcon style={{ color: "white" }} />
      </Fab>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 10px;
  display: flex;
  overflow-x: scroll;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  width: 95%;
  margin: 0 auto;
  height: 70px;
  background-color: #fff;
`;
