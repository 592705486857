import { useCallback, useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { MenuData } from "../api/types";
import { GetSpecificData, imageResize, isExtensionValid } from "../api/helper";
import { LoadingAnimation } from "../component/LoadingAnimation";
import { ref, uploadString } from "firebase/storage";
import { storage } from "../api/Firebase";
import classNames from "classnames";
import { Link } from "react-router-dom";

export const ImageUpload = () => {
  const [menu, setMenu] = useState<MenuData | null>();

  useEffect(() => {
    GetSpecificData("menu", "VzQk5jNwTq1EHPAKUXlt").then((data) => {
      setMenu(data as MenuData);
    });
  }, []);
  const onDrop = useCallback((file: File[]) => {
    if (file && file[0]) {
      if (!isExtensionValid(file[0].name)) {
        alert(
          "画像形式が正しくありません。iOSの場合、設定->カメラからフォーマット形式を選択し、「互換性優先」に切り替えてから再度お試しください。"
        );
        return;
      }
      setMenu(null);
      const reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onloadend = async () => {
        const base64 = reader.result as string;
        const resize = (await imageResize(base64)) as string;
        const storageRef = ref(storage, "menu/nanohana.jpg");
        await uploadString(storageRef, resize.split(",")[1], "base64");
        window.location.reload();
      };
    }
  }, []);

  const { getRootProps, isDragActive, getInputProps } = useDropzone({ onDrop });

  return (
    <main
      className="grid grid-rows-[25%_auto_35%]"
      style={{
        background: "linear-gradient(200deg, #00566b 0%, #1c1b64 100%)",
      }}
    >
      <h1 className="flex items-center justify-center text-center text-5xl text-white">
        {menu?.title}のサムネイル画像
      </h1>
      {menu ? (
        <img
          src={menu.image}
          {...getRootProps()}
          alt="menu"
          className={classNames("mx-auto max-w-xs rounded-xl", {
            "border border-solid border-sky-500": isDragActive,
          })}
        />
      ) : (
        <LoadingAnimation type={"leapfrog"} />
      )}
      <div>
        <input {...getInputProps()} />
        <Button
          {...getRootProps()}
          color="success"
          variant="contained"
          className="mx-auto my-5 flex items-start justify-center rounded-[30px] px-7 py-5"
        >
          写真を変更する
        </Button>
      </div>
      <Link to="/">
        <Button
          fullWidth
          className="fixed bottom-0 left-0 h-[80px] text-3xl"
          variant="contained"
        >
          注文一覧に戻る
        </Button>
      </Link>
    </main>
  );
};
